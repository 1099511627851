import React from "react";
import Header from "../components/Header";
import Header2 from "../components/Header2";
import About from "../components/About";
function Buy() {
  return (
    <div>
      <Header />
      <Header2 />
      <About />
    </div>
  );
}

export default Buy;
